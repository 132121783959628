import React from 'react'

function Documentview() {
  return (
    <div>
      <img src="/images/non-disclosure-agreement-uplead 1.png" alt="" />
    </div>
  )
}

export default Documentview
